<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn small depressed color="fmq_gray" dark outlined to="/">
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <ProdutoFormulario
          @send="criar"
          @validade="
            error = true;
            messageError = $event;
          "
          :item="item"
          :loadingBtn="loadingBtn"
          :listLines="listLines"
          :listLaboratories="listLaboratories"
          :listClassifications="listClassifications"
          :listAnatomiesPhysiologies="listAnatomiesPhysiologies"
          :listPathologies="listPathologies"
          :listProducts="listProducts"
          :listSpecialties="listSpecialties"
          :listSalesFroces="listSalesFroces"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Produto criado com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { listSaleForce } from "@/services/sale-force.js";
import { listSpecialty } from "@/services/specialty";
import { listLine } from "@/services/line";
import { listLaboratory } from "@/services/laboratory";
import { listClassification } from "@/services/classification";
import { listAnatomyPhysiology } from "@/services/anatomy-physiology";
import { listPathology } from "@/services/pathology";
import { listProduct, storeProduct } from "@/services/product";
import ProdutoFormulario from "@/components/produtos/ProdutoFormulario.vue";
export default {
  name: "ProdutoCriar",
  components: { ProdutoFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Produtos",
        disabled: false,
        to: "/",
      },
      {
        text: "Criar Produto",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    item: {},
    loading: false,
    listLines: [],
    listLaboratories: [],
    listClassifications: [],
    listAnatomiesPhysiologies: [],
    listPathologies: [],
    listProducts: [],
    listSpecialties: [],
    listSalesFroces: [],
  }),
  created() {
    this.listarLinhas();
    this.listarLaboratorios();
    this.listarClassificacoes();
    this.listarAnatomiasPatologias();
    this.listarPatologias();
    this.listarProdutos();
    this.listarEspecialidades();
    this.listarForcaDeVemdas();
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await storeProduct(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async listarForcaDeVemdas() {
      const resp = await listSaleForce();
      this.listSalesFroces = resp.data;
    },
    async listarLinhas() {
      const resp = await listLine();
      this.listLines = resp.data;
    },
    async listarLaboratorios() {
      const resp = await listLaboratory();
      this.listLaboratories = resp.data;
    },
    async listarClassificacoes() {
      const resp = await listClassification();
      this.listClassifications = resp.data;
    },
    async listarAnatomiasPatologias() {
      const resp = await listAnatomyPhysiology();
      this.listAnatomiesPhysiologies = resp.data;
    },
    async listarPatologias() {
      const resp = await listPathology();
      this.listPathologies = resp.data;
    },
    async listarProdutos() {
      let data = new FormData();
      data.append("labels[]", "name");
      data.append("labels[]", "laboratory:name");
      const resp = await listProduct(data);
      this.listProducts = resp.data;
    },
    async listarEspecialidades() {
      const resp = await listSpecialty();
      this.listSpecialties = resp.data;
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "Produtos" });
    },
  },
};
</script>

<style></style>
